import { Magic } from 'magic-sdk';
import { ForwardRequestTypedData } from '../interfaces/forward-request.interface';
import { ethers } from 'ethers';

declare let window: any;
let magic: Magic;

export const publicRpcProvider = new ethers.JsonRpcProvider(
  process.env.REACT_APP_PUBLIC_RPC!
);

// do not initialize unless used
export const getMagic = () => {
  if (!magic) {
    magic = new Magic(process.env.REACT_APP_MAGIC_API_KEY!, {
      network: {
        rpcUrl: process.env.REACT_APP_MAGIC_RPC_URL!,
        chainId: +process.env.REACT_APP_WEB3_NETWORK_ID!,
      },
    });
  }

  return magic;
};

let browserRpcProvider: ethers.BrowserProvider | undefined;
let magicRpcProvider: ethers.BrowserProvider | undefined;

export const getBrowserRpcProvider = () => {
  if (!browserRpcProvider) {
    browserRpcProvider = new ethers.BrowserProvider(window.ethereum);
  }

  return browserRpcProvider;
};

export const getMagicRpcProvider = () => {
  if (!magicRpcProvider) {
    magicRpcProvider = new ethers.BrowserProvider(getMagic().rpcProvider);
  }

  return magicRpcProvider;
};

let providerService: 'metamask' | 'magic' = 'metamask';

export const getProvider = () => {
  return providerService === 'metamask'
    ? getBrowserRpcProvider()
    : getMagicRpcProvider();
};

export const switchWeb3Provider = (provider: 'metamask' | 'magic') => {
  providerService = provider;
};

export const getCurrentProviderService = () => providerService;

export const getWalletAddress = async (
  networkId: string,
  hasToBeAddress?: string
) => {
  let fetchedAccounts;

  if (providerService === 'metamask') {
    if (!window.ethereum && !window.web3) {
      throw 'noMetamask';
    }

    await window.ethereum.enable();

    const currentNetId = +(await window.ethereum.request({
      method: 'eth_chainId',
    }));

    if (currentNetId.toString() !== networkId) {
      throw 'invalidNetwork';
    }

    fetchedAccounts = await window.ethereum.request({
      method: 'eth_requestAccounts',
    });
  } else {
    // await getMagic().wallet.connectWithUI();

    fetchedAccounts = [await (await getProvider().getSigner()).getAddress()];
  }

  console.log('accont received', fetchedAccounts);

  if (
    hasToBeAddress &&
    fetchedAccounts[0].toLowerCase() !== hasToBeAddress.toLowerCase()
  ) {
    throw 'invalidAddress';
  }

  return fetchedAccounts[0];
};

export const generatePersonalSignature = async (
  message: string,
  address: string
) => {
  if (getCurrentProviderService() === 'metamask') {
    return (window as any).ethereum.request({
      method: 'personal_sign',
      params: [message, address],
    });
  }

  return (await getProvider().getSigner()).signMessage(message);
};

export const generateTypedSignature = async (
  forwardRequest: ForwardRequestTypedData,
  address: string
) => {
  return (await getProvider().getSigner()).signTypedData(
    forwardRequest.domain,
    forwardRequest.types,
    forwardRequest.message
  );
};

export const getChainScannerUrl = (value: string) => {
  if (ethers.isAddress(value)) {
    return process.env.REACT_APP_SCANNER_ADDRESS_URL!.replace(
      '{{ADDRESS}}',
      value
    );
  } else {
    return process.env.REACT_APP_SCANNER_TX_URL!.replace('{{TX}}', value);
  }
};
